import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldComponent } from './m-form-field.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { ParagraphModule } from '../../atoms/a-paragraph/a-paragraph.module';

@NgModule({
    declarations: [FormFieldComponent],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, NgxMaskModule.forRoot(), ParagraphModule],
    exports: [FormFieldComponent],
})
export class FormFieldModule {}
