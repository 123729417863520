import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalWaitlistComponent } from './o-waitlist-modal.component';
import { IconModule } from '@src/app/@shared/atomic/atoms/a-icon/a-icon.module';
import { WaitlistFormModule } from '../o-waitlist-form/o-waitlist-form.module';

@NgModule({
    declarations: [ModalWaitlistComponent],
    imports: [CommonModule, IconModule, WaitlistFormModule],
    exports: [ModalWaitlistComponent],
})
export class WaitlistModalModule {}
