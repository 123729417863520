import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
/* import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'; */
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell.component';
import { FooterModule } from '../@shared/atomic/organisms/o-footer/o-footer.module';
import { NavbarModule } from '../@shared/atomic/organisms/o-navbar/o-navbar.module';
import { IconModule } from '../@shared/atomic/atoms/a-icon/a-icon.module';
import { ImageModule } from '../@shared/atomic/atoms/a-image/a-image.module';
import { ModalModule } from '../@shared/atomic/molecules/m-modal/m-modal.module';

@NgModule({
    imports: [CommonModule, RouterModule, FooterModule, NavbarModule, IconModule, ImageModule, ModalModule],
    declarations: [ShellComponent],
})
export class ShellModule {}
