import ERC721 from '@contracts/ERC721Mintable.json';
import MarketplaceAbi from '@contracts/Marketplace.json';

export interface ICdnElements {
    elType: string;
    src: string;
    active: boolean;
    isAsync?: boolean;
    isDefer?: boolean;
    isCrossorigin?: boolean;
    type?: string;
    rel?: string;
}

export enum messageAction {
    Login,
    Logout,
}

export class AppSettings {
    public static CHAIN_NET = {
        97: 'https://testnet.bscscan.com/tx',
        4: 'https://rinkeby.etherscan.io/tx',
        5: 'https://goerli.etherscan.io/',
    };

    public static CHAIN_NETWORK = {
        97: 'Binance Smart Chain - Testnet',
        4: 'Rinkeby - Testnet',
        5: 'Goerli - Testnet',
        1: 'Ethereum Mainnet',
    };

    public static PRERENDERING_ROUTES = ['/', '/puzzle'];
    public static STATIC_ROUTES = ['/', '/home', '/meet-the-gang', '/story', '/puzzle'];
    public static CLIENT_CONTRACTS = [
        { contract: ERC721, methods: ['mint', 'approveNFT', 'checkApprovalForNFT'] },
        {
            contract: MarketplaceAbi,
            methods: [
                'addItemToMarketplace',
                'removeItemFromMarketplace',
                'updatePriceOnMarketplace',
                'buyItemFromMarketplace',
            ],
        },
    ];

    public static CDN_ELEMENTS = [
        {
            elType: 'link', // Vime: The library is lazy loaded so you have nothing to worry about, only what you use will be loaded.
            src: 'https://cdn.jsdelivr.net/npm/@vime/core@^5/themes/default.css',
            rel: 'stylesheet',
            active: true,
        },
        {
            elType: 'link',
            src: 'https://cdn.lineicons.com/3.0/lineicons.css',
            rel: 'stylesheet',
            active: false,
        },
        {
            elType: 'script',
            src: 'https://cdn.jsdelivr.net/npm/@vime/core@^5/dist/vime/vime.esm.js',
            type: 'module',
            isDefer: true,
            active: true,
        },
        {
            elType: 'script',
            src: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDr7Q3TPIRgABAktvsFG7eDTgG6sqSW178',
            isAsync: true,
            active: false,
        },
        {
            elType: 'link',
            src: 'https://fonts.googleapis.com',
            rel: 'preconnect',
            active: false,
        },
        {
            elType: 'link',
            src: 'https://fonts.gstatic.com',
            rel: 'preconnect',
            isCrossorigin: true,
            active: false,
        },
    ];

    public static SUPPORTED_LANGUAGES = ['en-US'];
    public static I18NPAGES = ['resources', 'login'];
    public static DEFAULT_LANGUAGE = 'en-US';
    public static GOOGLE_API_KEY = 'AIzaSyDr7Q3TPIRgABAktvsFG7eDTgG6sqSW178';
    public static RECAPTCHA_SITE_KEY = '';
    public static SITE = 'basemint-gang'; //'nft-project';
    public static SEO_SETTING = '/assets/data/seo.json';
    public static MAX_CACHE_AGE = 60000;
    public static BLOG_PAGE_SIZE = 12;
    public static MARKETPLACE_PAGE_SIZE = 20;
    public static PAGE_SIZE = 5;
}
