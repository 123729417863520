import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICnnMetaMaskState, initialState } from './models/user.models';
import { Store } from '@ngrx/store';
import * as actions from '../store/actions';
import { AppState } from './models';
import { PlatformService } from '../@shared/utils/platform/platform.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    constructor(private store: Store<AppState>, private platform: PlatformService) {}

    getUser(): Observable<ICnnMetaMaskState> {
        return this.store.select('user').pipe(
            map((x) => {
                // if (this.platform.isBrowser && x === initialState) {
                //     const _user = JSON.parse(localStorage.getItem('user') || '{}');
                //     if (_user) {
                //         return _user as ICnnMetaMaskState;
                //     }
                // }
                return x;
            }),
        );
    }

    metamaskConnected(user: ICnnMetaMaskState) {
        this.store.dispatch(actions.metamaskConnected({ user }));
    }

    metamaskDisconnected() {
        this.store.dispatch(actions.metamaskDisconnected());
    }

    updateHasSupportedNetwork(supportedNetwork: boolean) {
        this.store.dispatch(actions.updateHasSupportedNetwork({ supportedNetwork }));
    }

    getHasSupportedNetwork() {
        return this.store.select('network');
    }
}
