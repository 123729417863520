import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class UserEffects {
    constructor(private actions$: Actions) {}

    saveUserLocalStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.metamaskConnected),
                tap((action) => localStorage.setItem('user', JSON.stringify(action.user))),
            ),
        { dispatch: false },
    );

    removeUserLocalStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.metamaskDisconnected),
                tap(() => localStorage.removeItem('user')),
            ),
        { dispatch: false },
    );
}
