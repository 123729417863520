import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingStateService } from '@services/loading-state.service';
import { MessageServiceModel } from '../utils/message/message.model';
import { SeoService } from '../utils/seo/seo.service';
import { ModalParam } from '../atomic/molecules/m-modal/m-modal.model';
import { AppInjector } from '../../app.module';
import { ComponentBase } from './component.base';
import { commonMessageAction } from '../utils/message/message.service';

@Component({
    template: '',
})
export abstract class PageBase extends ComponentBase implements OnInit {
    videoModalHandler: ModalParam = { closeResult: '', open: false };
    seo: SeoService;
    router: Router;
    loadingStateService: LoadingStateService;

    constructor() {
        super();
        this.loadingStateService = AppInjector.get(LoadingStateService);
        this.seo = AppInjector.get(SeoService);
        this.router = AppInjector.get(Router);
    }

    ngOnInit(): void {
        this.seo.addSeoSetting(this.router.url);
        /*         const subscriptionSeo = this.seo.addSeoSetting(this.router.url);
        this.subscriptions.push(subscriptionSeo); */

        if (this.platform.isServer) return;
        this.loadingStateService?.initLoader();

        if (this.messageService) {
            const subscriptionMessage = this.messageService.getMessage().subscribe((msj: MessageServiceModel) => {
                if (msj.type === commonMessageAction.Modal) {
                    this.videoModalHandler = { closeResult: '', open: msj.payload };
                }
            });
            this.subscriptions.push(subscriptionMessage);
        }
    }
}
