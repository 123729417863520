import { createReducer, on, Action } from '@ngrx/store';
import * as actions from '../actions';

export const _networkReducer = createReducer(
    false,
    on(actions.updateHasSupportedNetwork, (state: boolean, { supportedNetwork }) => (state = supportedNetwork)),
);

export function networkReducer(state: boolean = false, action: Action) {
    return _networkReducer(state, action);
}
