import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    OnDestroy,
    ViewEncapsulation,
    AfterViewInit,
} from '@angular/core';
import { VideoComponentOptions } from './a-video.model';
import { MediaService } from '../../../utils/media/media.service';

declare var videojs: any;

@Component({
    selector: 'a-video',
    templateUrl: './a-video.component.html',
    styleUrls: ['./a-video.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('target') target!: ElementRef;
    @ViewChild('source') source!: ElementRef;
    @Input() video!: VideoComponentOptions;
    @Input() isPlaying: boolean = false;
    @Input() containerClass?: string;
    @Input() set src(value: string) {
        this._src = value;
        if (this._src) this.updateSrc(this._src);
    }

    get src(): string {
        return this._src;
    }

    private _src: string = '';
    player: any;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        this.player = videojs(this.target.nativeElement, this.video, () => this.onPlayerReady());
    }

    onPlayerReady() {
        this.player.on('ended', () => {
            this.isPlaying = false;
        });
        if (this.isPlaying) {
            this.player.play();
        }
    }
    onPlayPause(event: any) {
        if (this.isPlaying) {
            this.player.pause();
        } else {
            this.player.play();
        }
        this.isPlaying = !this.isPlaying;
    }

    ngOnDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }

    updateSrc(src: string) {
        setTimeout(() => {
            var videoElm: any = this.target.nativeElement;
            var videoSourceElm: any = this.source.nativeElement;
            if (!videoElm.paused) {
                videoElm.pause();
            }
            videoSourceElm.src = src;
            videoSourceElm.type = MediaService.getContentFromUrl(src);

            videoElm.load();
            videoElm.play();
        }, 100);
    }
}
