import { CMSList } from '@app/@shared/cms/models/cms-list.model';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { mapLegalDocToFooterLinks } from '@app/mappers/legaldoc.mapper';
import { PagesService } from '../services/pages.service';
import { LegalDoc } from './../@shared/cms/models/legaldoc.model';
import { LoadingStateService } from '../services/loading-state.service';
import { Shell } from './shell.service';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@src/environments/environment';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { commonMessageAction, MessageService } from '../@shared/utils/message/message.service';
import { MessageServiceModel } from '../@shared/utils/message/message.model';
import { PlatformService } from '../@shared/utils/platform/platform.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit, OnDestroy {
    navbarMenu!: any[];
    footerItems!: any[];
    hideInRoutes!: string[];
    showConnectRoutes!: string[];
    footerCopyItems: Array<any> = [];
    showFooter: boolean = true;
    subscription: Subscription | undefined;
    date = new Date().getFullYear();
    logoUrl: string = `${environment.baseUrl}/assets/icons/logo.svg`;
    showPage: boolean = false;

    constructor(
        private pagesService: PagesService,
        public loadingStateService: LoadingStateService,
        private shellServide: Shell,
        private router: Router,
        private state: TransferState,
        public messageService: MessageService,
        private platform: PlatformService,
        private modalService: NgbModal,
    ) {}

    ngOnInit() {
        if (this.platform.isBrowser) {
            this.messageService.getMessage().subscribe((msj: MessageServiceModel) => {
                if (msj.type === commonMessageAction.Loading) {
                    if (!msj.payload.show) {
                        document.body.style.backgroundColor = '#191C1F';
                        this.showPage = true;
                    }
                }
            });
        }
        this.hideInRoutes = ['/'];
        this.showConnectRoutes = ['gallery', 'my-nfts', 'training-gym', 'puzzle'];
        this.navbarMenu = this.shellServide.getNavbarMenu();
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.findRoutesExclude(val.url);
            }
        });
        this.findRoutesExclude(this.router.url);
        this.loadFooterItems();
        this.footerCopyItems = [
            {
                label:
                    '© 2011 - ' +
                    this.date +
                    ' Scalio - <a class="text-decoration-none email-contrast" title="hello@scal.io" href="mailto:hello@scal.io?Subject=Hello%20Scalio!">hello@scal.io</a>',
            },
        ];

        const RESULT_KEY = makeStateKey<any>('shell-http-call');
        if (this.state.hasKey(RESULT_KEY)) {
            const policyDetail = this.state.get(RESULT_KEY, {});
            this.loadData(policyDetail);
        } else {
            this.subscription = this.pagesService
                .getPolicies({ sort: 'timeCreated', limit: 3, page: 1 })
                .subscribe((policyDetail) => {
                    if (this.platform.isServer) {
                        this.state.set(RESULT_KEY, policyDetail);
                    }
                    this.loadData(policyDetail);
                });
        }
    }

    loadData(policyDetail: CMSList<LegalDoc>) {
        const policies = policyDetail as CMSList<LegalDoc>;
        this.footerCopyItems = [...this.footerCopyItems, ...mapLegalDocToFooterLinks(policies.data, 'policy')];
    }

    findRoutesExclude(url: string) {
        const idx = this.hideInRoutes.findIndex((x) => url.includes(x));
        this.showFooter = idx === -1;
    }

    loadFooterItems() {
        this.footerItems = [{}, {}, {}];
    }

    closeModal() {
        this.modalService.dismissAll();
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
