import { VideoOptions } from './o-scalio-video.model';
import { PlatformService } from '../../../../@shared/utils/platform/platform.service';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'o-scalio-video-section',
    templateUrl: './o-scalio-video-section.component.html',
    styleUrls: ['./o-scalio-video-section.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ScalioVideoSectionComponent implements OnInit {
    @Input() video!: VideoOptions;
    @Input() overlay: boolean = false;
    @Input() hasVideo: boolean = false;
    @Input() isPlaying: boolean = false;
    @Input() title!: string;
    @Input() content!: string;
    @Input() btnLabel!: string;
    @Input() btnLink!: string;
    @Input() btnClassName: string = '';
    @Input() btnVideoClassName: string = '';
    @Input() btnSecondaryLabel!: string;
    @Input() btnSecondaryClassName!: string;
    @Input() hasLine!: boolean;
    @Input() containerClass?: string;

    constructor(private platform: PlatformService) {}

    ngOnInit(): void {}

    goDown() {
        if (this.platform.isBrowser) {
            document.getElementById('nextSection')?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest',
            });
        }
    }
}
