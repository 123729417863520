import {
    Component,
    OnInit,
    OnDestroy,
    AfterViewInit,
    Inject,
    PLATFORM_ID,
    isDevMode,
    Renderer2,
    NgZone,
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge, timer } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { environment as prodEnvironment } from '@env/environment.prod';
import { UntilDestroy, untilDestroyed } from '@shared';
import Aos from 'aos';
import { Logger } from './@shared/utils/logger/logger.service';
import { LoadingStateService } from './services/loading-state.service';
import { I18nService } from './@shared/utils/i18n/i18n.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { AppSettings } from './app.settings';
import { CommonService } from './@shared/utils/common/common.service';
import { StoreService } from './store/store.service';

const log = new Logger('App');
declare let gtag: any;
declare var ethereum: any;

@UntilDestroy()
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
    private isBrowser: boolean;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private renderer: Renderer2,
        private titleService: Title,
        private translateService: TranslateService,
        private loadingStateService: LoadingStateService,
        private i18nService: I18nService,
        private commonService: CommonService,
        private _ngZone: NgZone,
        private storeService: StoreService,
        @Inject(DOCUMENT) private _document: Document,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    async ngOnInit() {
        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        //this.httpCacheService.excludedUrlToCache = ['https://mcms-api.stage.scaliolabs.com/papi/events?page=1&limit=0&sort=-startDate&past=false'];

        log.debug('init');

        if (this.isBrowser && window.location.href.includes(prodEnvironment.baseUrl)) {
            this.injectScripts();
            const navEndEvent$ = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));
            navEndEvent$.subscribe((e: any) => {
                gtag('config', 'G-Y10WW8J3GQ', { page_path: e.urlAfterRedirects });
            });
        }

        // Setup translations
        this.i18nService.init(AppSettings.DEFAULT_LANGUAGE, AppSettings.SUPPORTED_LANGUAGES);

        const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

        // Change page title on navigation or language change, based on route data
        merge(this.translateService.onLangChange, onNavigationEnd)
            .pipe(
                map(() => {
                    let route = this.activatedRoute;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => route.outlet === 'primary'),
                switchMap((route) => route.data),
                untilDestroyed(this),
            )
            .subscribe((event) => {
                const title = event.title;
                if (title) {
                    this.titleService.setTitle(this.translateService.instant(title));
                }
            });

        if (this.isBrowser) {
            Aos.init();
            ethereum = window['ethereum'];
            if (ethereum) {
                this.storeService.updateHasSupportedNetwork(
                    +ethereum.networkVersion === environment.moralis.chainId,
                );
                ethereum.on('chainChanged', (accounts: string) => {
                    this._ngZone.runOutsideAngular(() => {
                        this._ngZone.run(() => {
                            this.storeService.updateHasSupportedNetwork(
                                parseInt(accounts) === environment.moralis.chainId,
                            );
                        });
                    });
                });
            }
            if (isDevMode()) timer(0).subscribe(() => this.loadingStateService.initAppLoader());
        }
    }

    ngAfterViewInit() {
        if (this.isBrowser && !isDevMode()) this.loadingStateService.initAppLoader();
        this.commonService.dynamicImport();
    }

    ngOnDestroy() {
        this.i18nService.destroy();
    }

    injectScripts() {
        if (this.isBrowser) {
            const gtmScriptTag = this.renderer.createElement('script');
            gtmScriptTag.type = 'text/javascript';
            gtmScriptTag.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y10WW8J3GQ';
            this.renderer.appendChild(this._document.body, gtmScriptTag);

            const gtagInitScript = this.renderer.createElement('script');
            gtagInitScript.type = 'text/javascript';
            gtagInitScript.text = `
            window.dataLayer = window.dataLayer || [];
    
            function gtag() {
            dataLayer.push(arguments);
            }
            gtag('js', new Date());
    
            gtag('config', 'G-Y10WW8J3GQ', { 'send_page_view' : false });
            `;
            this.renderer.appendChild(this._document.body, gtagInitScript);
        }
    }
}
