<section class="a-network-change">
    <div
        (click)="onCloseClick()"
        class="close-button"
        role="button">
        <a-icon
            [white]="true"
            aria-label="Close"
            source="/assets/icons/close-icon.svg"></a-icon>
    </div>
    <div *ngIf="hasMetamaskExtension">
        <h2 class="mt-4 text-center">Network not supported</h2>
        <div class="pb-4 text-center">
            <article>We do not support the network selected.</article>
            <article>Switch back to {{ chainName }} and sign in.</article>
        </div>

        <div class="d-grid gap-2">
            <!-- [ngIf]="!btnDisabled" -->
            <!-- [disabled]="btnDisabled" -->
            <!--             <button
                [disabled]="btnDisabled"
                type="button"
                class="btn btn-primary"
                (click)="onConnect()">
                <span>Sign In</span>
            </button> -->
            <!-- <button
                type="button"
                class="btn btn-light"
                (click)="gotoHome()">
                <span>Take me home</span>
            </button> -->
        </div>
    </div>
    <!-- <ng-template #noExtension>
        <div>
            <h2 class=""><strong>Connect wallet</strong></h2>
            <div class="pb-4">Choose one of available wallet providers or create a new wallet.</div>
            <div class="d-grid gap-4">
                <a
                    class="btn btn-light connect-wallet-btn text-start"
                    target="_blank"
                    href="https://metamask.io/download/">
                    <img src="/assets/icons/metamask.svg" />
                    <span>Metamask</span>
                </a>
                 <a
                    class="btn btn-light connect-wallet-btn text-start"
                    target="_blank"
                    href="https://metamask.io/download/">
                    <img src="/assets/icons/wallet.svg" />
                    <span>Wallet Connec&zwnj;t</span>
                </a>
            </div>
        </div>
    </ng-template> -->
</section>
