import { createReducer, on, Action } from '@ngrx/store';
import * as actions from '../actions';
import { initialState, ICnnMetaMaskState } from '../models/user.models';

export const _userReducer = createReducer(
    initialState,
    on(actions.metamaskConnected, (state: ICnnMetaMaskState, { user }) => (state = { ...user })),
    on(actions.metamaskDisconnected, (state) => (state = { ...initialState })),
);

export function userReducer(state: ICnnMetaMaskState = initialState, action: Action) {
    return _userReducer(state, action);
}
