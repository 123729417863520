import { Component, Input, OnInit, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../../../../@shared/utils/message/message.service';
import { messageAction, AppSettings } from '../../../../app.settings';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@src/environments/environment';

declare var ethereum: any;

@Component({
    selector: 'a-network-change',
    templateUrl: './a-network-change.component.html',
    styleUrls: ['./a-network-change.component.scss'],
})
export class NetworkChangeComponent implements OnInit {
    @Input('btnDisabled') btnDisabled: boolean = true;
    @Output() onClose = new EventEmitter<any>();
    private isBrowser: boolean;
    chainName: string = AppSettings.CHAIN_NETWORK[environment.moralis.chainId];
    hasMetamaskExtension: any;

    constructor(
        private router: Router,
        private messageService: MessageService,
        @Inject(PLATFORM_ID) platformId: Object,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit(): void {
        if (this.isBrowser) {
            ethereum = window['ethereum'];
            this.hasMetamaskExtension = ethereum;
        }
    }

    onCloseClick() {
        this.onClose.emit();
    }

    onConnect() {
        this.messageService.sendMessage(messageAction.Login);
    }

    gotoHome() {
        this.router.navigate(['/gallery']);
    }
}
