import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { tap } from 'rxjs/operators';

@Injectable()
export class NetworkEffects {
    constructor(private actions$: Actions) {}

    saveNetworkLocalStorage$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(actions.updateHasSupportedNetwork),
                tap((action) => localStorage.setItem('hasSupportedNetwork', JSON.stringify(action.supportedNetwork))),
            ),
        { dispatch: false },
    );
}
