import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { timer } from 'rxjs';
import { commonMessageAction, MessageService } from '../@shared/utils/message/message.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class LoadingStateService {
    refreshing = false;
    private isBrowser: boolean;
    constructor(private message: MessageService, @Inject(PLATFORM_ID) platformId: Object) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    initAppLoader() {
        this.refreshing = true;
        this.message.sendMessage(commonMessageAction.Loading, {
            show: true,
            animatedLogo: true,
            hasOnlyBackground: false,
        });
        timer(this.isBrowser ? 1000 : 0).subscribe((time) => {
            this.stopLoader();
        });
    }

    initLoader() {
        if (!this.isBrowser || this.refreshing) return;
        this.message.sendMessage(commonMessageAction.Loading, {
            show: true,
            animatedLogo: true,
            hasOnlyBackground: false,
        });
    }

    stop() {
        if (!this.isBrowser) return;
        timer(this.isBrowser ? 1000 : 0).subscribe((time) => {
            this.stopLoader();
        });
    }

    private stopLoader() {
        if (this.refreshing) {
            this.refreshing = false;
        } else {
            this.message.sendMessage(commonMessageAction.Loading, {
                show: false,
                animatedLogo: true,
                hasOnlyBackground: false,
            });
        }
    }
}
