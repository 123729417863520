import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { WaitlistFormComponent } from './o-waitlist-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldModule } from './../../../../@shared/atomic/molecules/m-form-field/m-form-field.module';
import { ButtonModule } from './../../../../@shared/atomic/atoms/a-button/a-button.module';
import { IconModule } from '@src/app/@shared/atomic/atoms/a-icon/a-icon.module';
@NgModule({
    declarations: [WaitlistFormComponent],
    imports: [CommonModule, TranslateModule, ReactiveFormsModule, FormFieldModule, ButtonModule, IconModule],
    exports: [WaitlistFormComponent],
})
export class WaitlistFormModule {}
