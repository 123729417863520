import { Injectable } from '@angular/core';
import { messageAction } from '@src/app/app.settings';
import { Subject, Observable } from 'rxjs';
import { MessageServiceModel } from './message.model';

/**
 * This service allows us to send messages between components of the same level or messages between very distant elements in the same hierarchy.
 */

export enum commonMessageAction {
    Accordion,
    Modal,
    UpdateUserDetail,
    Loading,
    FormEvent,
    SearchFilter,
    TextScrambler,
}

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    private subject = new Subject<any>();
    constructor() {}

    sendMessage(type: commonMessageAction | messageAction, payload: any = null) {
        this.subject.next({
            type,
            payload,
        });
    }

    getMessage(): Observable<MessageServiceModel> {
        return this.subject.asObservable();
    }
}
