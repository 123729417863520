// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
    production: true,
    version: env.npm_package_version,
    serverUrl: 'https://mcms-api.scal.io/',
    baseUrl: 'https://basemintgang.com', //appDomain baseUrl: 'http://localhost:4000'
    awsS3: {
        AWSAccessKeyId: 'AKIAVSK2676HTM6F5YHK',
        policy: 'ewogICJleHBpcmF0aW9uIjogIjIwMjktMDEtMDFUMDA6MDA6MDBaIiwKICAiY29uZGl0aW9ucyI6IFsKICAgIHsiYnVja2V0IjogImNkbi1zY2FsaW9tY21zLXByb2QifSwKICAgIFsic3RhcnRzLXdpdGgiLCAiJGtleSIsICIiXSwKICAgIHsiYWNsIjogInB1YmxpYy1yZWFkIn0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRDb250ZW50LVR5cGUiLCAiIl0sCiAgICBbInN0YXJ0cy13aXRoIiwgIiRmaWxlbmFtZSIsICIiXSwKICAgIFsiY29udGVudC1sZW5ndGgtcmFuZ2UiLCAwLCA1MjQyODgwMDBdCiAgXQp9',
        signature: 'Li4xEBRReuq45X/C1SI1yZKfQoU=',
        baseUrl: 'https://cdn-scaliomcms-prod.s3.amazonaws.com',
    },
    navbarMenu: [
        {
            label: 'Story',
            url: '/story',
        },
        {
            label: 'Meet the Gang',
            url: '/meet-the-gang',
        },
        {
            label: 'Puzzle',
            url: '/puzzle',
        },
        {
            label: 'Training Gym',
            url: '/training-gym',
        },
    ],
    moralis: {
        appId: 'skEgO48Z2R9C3rVWDJIIbk8Tqc47UkGBcGTEtiZG',
        serverUrl: 'https://8mlkgmyrrvpz.usemoralis.com:2053/server',
        hardHatURL: 'http://127.0.0.1:8545', // JsonRPC URL for Hardhat
        nodeURL: 'http://127.0.0.1:7545', // JsonRPC URL for Ganache
        NFTcontractAddress: '0x03e113be5992e6358ef677f774aba760b4e6517a', // Change after each contracts deployment in small letter. //0x03e113be5992e6358ef677f774aba760b4e6517a
        MarketplaceContractAddress: '0x9B3C441e583852Fb4E6C423Dd25FDDB31B881B39', // Rinkeby: 0x8c6a51e2ea8a00fbcaeb3597cf25621cac49350b //0x7c4Db3738e6793bE598fCa0ab23c00d70426b2Da
        MintContractAddress: '0xBE44B56bf60b5eE6141345C2B1380BeA2915d991',
        chainId: 1,
        chain: 'eth', // ETH //bsc
        BSCTestNet: 'https://testnet.bscscan.com/tx/',
        RINKEYTestNet: 'https://goerli.etherscan.io/tx/',
        RINKEYTestAddress: 'https://goerli.etherscan.io/address/',
        RPCUrl: 'https://goerli.infura.io/v3/3b521a5f7d5d47dcb6f40a45635b235c',
    },
};
