import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './welcome.component';
import { Shell } from '../../shell/shell.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

const routes: Routes = [
    Shell.childRoutes([
        {
            path: '',
            component: WelcomeComponent,
            data: { title: marker('welcome') },
        },
        {
            path: 'policy/:slug',
            loadChildren: () => import('./../policy/policy.module').then((m) => m.PolicyModule),
        },
        {
            path: 'home',
            loadChildren: () => import('../home/home.module').then((m) => m.HomeModule),
        },
        {
            path: 'meet-the-gang',
            loadChildren: () => import('../meet/meet.module').then((m) => m.MeetModule),
        },
        // {
        //     path: 'mint',
        //     loadChildren: () => import('../mint-now/mint-now.module').then((m) => m.MintNowModule),
        // },
        {
            path: 'story',
            loadChildren: () => import('../story/story.module').then((m) => m.StoryModule),
        },
        {
            path: 'puzzle',
            loadChildren: () => import('../puzzle/puzzle.module').then((m) => m.PuzzleModule),
        },
        {
            path: 'gallery',
            loadChildren: () => import('../gallery/gallery.module').then((m) => m.GalleryModule),
        },
        {
            path: 'my-nfts',
            loadChildren: () => import('../gallery/gallery.module').then((m) => m.GalleryModule),
        },
        {
            path: 'gallery/:id',
            loadChildren: () => import('../gallery/gallery.module').then((m) => m.GalleryModule),
        },
        {
            path: 'training-gym',
            loadChildren: () => import('../training-gym/training-gym.module').then((m) => m.TrainingGymModule),
        },
        { path: '**', redirectTo: '', pathMatch: 'full' },
    ]),
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class WelcomeRoutingModule {}
