import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    HostListener,
    ViewChild,
    ElementRef,
    Renderer2,
    NgZone,
} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TemplateRef, Output, EventEmitter, PLATFORM_ID, Inject } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from '@app/@shared/services/theme.service';
import { environment } from '@src/environments/environment';
import { CredentialsService } from '../../../cms/auth/credentials.service';
import { commonMessageAction, MessageService } from '../../../utils/message/message.service';
import { MessageServiceModel } from '../../../utils/message/message.model';
import { ModalParam } from '../../molecules/m-modal/m-modal.model';
import { NavigationModel } from '../../molecules/m-navbar-item/m-navbar-item.model';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { StoreService } from '../../../../store/store.service';
import { ICnnMetaMaskState } from '../../../../store/models/user.models';
import { AppSettings, messageAction } from '@src/app/app.settings';
import type { Web3Service } from '@src/app/services/web3.service';
import { LazyInject } from '@src/app/services/lazy-inject.service';

@Component({
    selector: 'o-navbar',
    templateUrl: './o-navbar.component.html',
    styleUrls: ['./o-navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnChanges {
    @ViewChild('nav') navbar!: ElementRef;
    @ViewChild('modalmenu') modalContent!: TemplateRef<any>;
    @ViewChild('networkchangePopup') networkchangePopup!: TemplateRef<any>;
    @ViewChild('accountChangePopup') accountChangePopup!: TemplateRef<any>;
    @Output() isOpenChange: EventEmitter<ModalParam> = new EventEmitter();
    @HostListener('window:scroll', ['$event'])
    onWindowScroll() {
        if (this.isBrowser && this.navbar && this.navbar.nativeElement) {
            let element = this.navbar.nativeElement;
            if (window.pageYOffset > 0) {
                element.classList.add('navbar-on-scroll');
            } else {
                element.classList.remove('navbar-on-scroll');
            }
        }
    }

    @Input() navItems: NavigationModel[] = [];
    @Input() copyItems: Array<any> = [];
    @Input() showThemeToggle: boolean = false;
    @Input() hasFrame: boolean = true;
    @Input() componentClass?: string;
    @Input() hideInRoutes: string[] = [];
    @Input() showConnectRoutes: string[] = [];
    showComponent: boolean = true;
    showConnectWallet: boolean = false;
    button?: NavigationModel;
    buttonMobile?: NavigationModel;
    items!: NavigationModel[];
    logoUrl: string = `${environment.baseUrl}/assets/img/logo.png`;
    lightLogoUrl: string = `${environment.baseUrl}/assets/img/logo.png`;
    iconClose: string = `${environment.baseUrl}/assets/icons/icon-close.svg`;
    modalId?: string;
    modalHandler: ModalParam = { closeResult: '', open: false };
    modalSaveloading!: boolean;
    user?: ICnnMetaMaskState;
    hasSupportedNetwork: boolean = false;
    returnUrl: string = '';
    private isBrowser: boolean;
    private isServer: boolean;
    web3Service!: Web3Service;

    walletError!: string;

    constructor(
        private router: Router,
        private credentialsService: CredentialsService,
        private message: MessageService,
        private modalService: NgbModal,
        private themeService: ThemeService,
        private renderer: Renderer2,
        private translateService: TranslateService,
        private storeService: StoreService,
        private _ngZone: NgZone,
        @Inject(PLATFORM_ID) platformId: Object,
        private lazyInjector: LazyInject,
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        this.isServer = isPlatformServer(platformId);
    }

    async ngOnInit(): Promise<void> {
        this.web3Service = await this.lazyInjector.get<Web3Service>(() =>
            import('../../../../services/web3.service').then((m) => m.Web3Service),
        );

        if (this.isBrowser && window && window.localStorage.getItem('user')) {
            window.localStorage.removeItem('user');
        }

        try {
            this.web3Service.setupWeb3();
        } catch (error) {
            console.log('error starting web3');
        }

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationStart) {
                this.showComponent = !this.hideInRoutes?.includes(val.url);
                const urlTree = this.router.parseUrl(val.url);
                const url = urlTree.root.children.primary ? urlTree.root.children.primary.segments[0].path : val.url;
                this.showConnectWallet = this.showConnectRoutes?.includes(url);
            }
        });

        this.storeService.getUser().subscribe((user: ICnnMetaMaskState) => {
            this.user = user;
        });

        this.showComponent = !this.hideInRoutes?.includes(this.router.url);
        const urlTree = this.router.parseUrl(this.router.url);
        const url = urlTree.root.children.primary ? urlTree.root.children.primary.segments[0].path : this.router.url;
        this.showConnectWallet = this.showConnectRoutes?.includes(url);
        if (this.navItems) {
            this.addNavigationItems(this.navItems);
        }

        this.message.getMessage().subscribe((msj: MessageServiceModel) => {
            if (msj.type === commonMessageAction.UpdateUserDetail) this.addNavigationItems(this.navItems);
            if (msj.type === messageAction.Login) {
                this.returnUrl = msj.payload ?? '';
                this.openModal();
            }
            if (msj.type === messageAction.Logout) {
                this.logout();
            }
        });

        if (this.isServer) return;

        this.web3Service.accountChangedSubject.subscribe((accounts) => {
            this._ngZone.runOutsideAngular(() => {
                this._ngZone.run(() => {
                    this.modalService.dismissAll();
                });
            });
            if (this.user && this.user.userAccountAddress && this.user.userAccountAddress !== accounts[0]) {
                this._ngZone.runOutsideAngular(() => {
                    this._ngZone.run(() => {
                        this.logout();
                        this.modalService.dismissAll();
                        this.modalService.open(this.accountChangePopup, {
                            centered: true,
                            backdrop: 'static',
                        });
                    });
                });
            }
        });

        this.web3Service.chainChangedSubject.subscribe((chain) => {
            this._ngZone.runOutsideAngular(() => {
                this._ngZone.run(() => {
                    this.modalService.dismissAll();
                });
            });
            if (this.user && this.user.chainId && chain && this.user.chainId !== Number(`${chain.slice(-1)}`)) {
                this._ngZone.runOutsideAngular(() => {
                    this._ngZone.run(() => {
                        this.logout();
                        this.modalService.dismissAll();
                        this.modalService.open(this.networkchangePopup, {
                            centered: true,
                            backdrop: 'static',
                        });
                    });
                });
            }
        });

        this.themeService.themeChanges().subscribe((theme) => {
            if (theme.oldValue) {
                this.renderer.removeClass(document.body, theme.oldValue);
            }
            this.renderer.addClass(document.body, theme.newValue);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.navItems || !changes.navItems.currentValue) return;

        this.addNavigationItems(changes.navItems.currentValue);
    }

    getbtnLabel(key: string): string {
        return this.translateService.instant(key);
    }

    addNavigationItems(navItems: NavigationModel[]) {
        const isAuth = this.credentialsService.isAuthenticated();
        if (!isAuth) {
            const itemBtn = navItems.filter((item: NavigationModel) => item.isBtn);
            if (itemBtn) {
                const btnDesktop = itemBtn.filter((item: NavigationModel) => item.hideMobile);
                if (btnDesktop) this.button = btnDesktop[0];
                const btnMobile = itemBtn.filter((item: NavigationModel) => item.hideDesktop);
                if (btnMobile) this.buttonMobile = btnMobile[0];
            }
        } else {
            this.button = undefined;
        }

        this.items = navItems.filter((item: NavigationModel) => {
            if (item.hideAuth) return isAuth ? false : true;
            else if (item.hideNoAuth) return isAuth ? true : false;
            else return true;
        });
    }

    click(close: boolean = false) {
        if (this.button) {
            if (this.button.isExternal) {
                var check = this.button.url.substring(0, 4);
                if (check == 'http') {
                    window.open(this.button.url, this.button.target);
                } else {
                    window.open('//' + this.button.url, this.button.target);
                }
            } else {
                this.router.navigateByUrl(this.button.url);
            }
        }

        if (close) this.closeMenu();
    }

    closeMenu() {
        this.modalService.dismissAll('close');
    }

    openMenu() {
        this.modalService.open(this.modalContent, { windowClass: 'fullscreen-modal' }).result.then(
            (result) => {
                this.isOpenChange.emit({ closeResult: `Closed with: ${result}`, open: false });
            },
            (reason) => {
                this.isOpenChange.emit({ closeResult: `Dismissed ${this.getDismissReason(reason)}`, open: false });
            },
        );
    }

    openConnectModal() {
        this.modalService.dismissAll();
        this.openModal();
    }

    async openModal() {
        await this.web3Service.openWeb3Modal();
        if (this.web3Service.connectedNetwork && this.web3Service.connectedNetwork !== environment.moralis.chainId) {
            this.modalService.open(this.networkchangePopup, {
                centered: true,
                backdrop: 'static',
            });
        }
    }

    logout() {
        this.web3Service.logout();
    }

    closeModal() {
        this.modalHandler = { closeResult: '', open: false };
    }

    truncateSting(str: string) {
        return str.substring(0, 5) + '.....' + str.substring(str.length - 5, str.length);
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
}
