<div
    class="o-scalio-video-section position-relative"
    [ngClass]="[containerClass ? containerClass : '']">
    <ng-container *ngIf="hasVideo">
        <a-video
            [video]="video"
            [isPlaying]="isPlaying"></a-video>
    </ng-container>
    <div
        *ngIf="overlay"
        class="overlay"></div>
    <div class="container-xxl py-0">
        <div class="content">
            <h1
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-once="true"
                [innerHtml]="title"
                class="lead contrast-color bold-weight"></h1>
            <h3
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-once="true"
                [innerHtml]="content"
                class="h4 contrast-color fw-lighter"></h3>
        </div>
    </div>
    <ng-content #logos></ng-content>
</div>
