import { Injectable } from '@angular/core';
import { ApiService } from '@app/@shared/http/api-service';
import { Observable, throwError, of, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SignUpContext } from '@app/@shared/cms/models/signup.model';
import { LoginContext } from '../models/login.model';
import { commonMessageAction, MessageService } from '../../utils/message/message.service';
import { CredentialsService } from './credentials.service';
import { ForgotPasswordContext, ResetPasswordContext } from '../models/forgot.model';
import { Account } from '../models/account.model';
import { User } from '../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private credentialsService: CredentialsService,
        private apiService: ApiService,
        private message: MessageService,
    ) {}

    /**
     * Authenticates the user.
     * @param context The login parameters.
     * @return The user credentials.
     */
    login(context: LoginContext): Observable<any> {
        const { username, password } = context;
        const data = { username, password };
        return this.apiService.post(environment.serverUrl + 'api/login', data).pipe(
            map((body: any) => {
                this.credentialsService.setCredentials(body, false);
                this.message.sendMessage(commonMessageAction.UpdateUserDetail, body.user);
                return body;
            }),
            catchError((err) => throwError(err)),
        );
    }

    isAuthorized() {
        const credentials = this.credentialsService.credentials;
        if (credentials) {
            if (credentials.user.roles['account']) return true;
        }
        return false;
    }

    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        // Customize credentials invalidation here
        this.credentialsService.setCredentials();
        this.message.sendMessage(commonMessageAction.UpdateUserDetail, null);
        return of(true);
    }

    /**
     * register the user.
     * @param context The signup parameters.
     * @return The user credentials.
     */
    signup(context: SignUpContext): Observable<any> {
        return this.apiService.post(environment.serverUrl + 'api/signup', context).pipe(
            map((body: any) => {
                this.credentialsService.setCredentials(body, false);
                this.message.sendMessage(commonMessageAction.UpdateUserDetail, body.user);
                return body;
            }),
            catchError((err) => throwError(err)),
        );
    }

    forgotPassword(context: ForgotPasswordContext): Observable<any> {
        return this.apiService
            .post(environment.serverUrl + 'api/login/forgot', context)
            .pipe(catchError((err) => throwError(err)));
    }

    resetPassword(context: ResetPasswordContext): Observable<any> {
        return this.apiService
            .post(environment.serverUrl + 'api/login/reset', context)
            .pipe(catchError((err) => throwError(err)));
    }

    checkEmail(email: string) {
        return this.apiService.get(environment.serverUrl + 'api/users/' + email + '/search').pipe(
            map((response: string) => {
                return true;
            }),
            catchError((err) => of(false)),
        );
    }

    checkUser(user: string) {
        return this.apiService.get(environment.serverUrl + 'api/username/check/' + user).pipe(
            map((response: string) => {
                return true;
            }),
            catchError((err) => of(false)),
        );
    }

    getAccount(): Observable<any> {
        return forkJoin({
            account: this.apiService.get(environment.serverUrl + 'api/accounts/my') as Observable<Account>,
            user: this.apiService.get(environment.serverUrl + 'api/users/my') as Observable<User>,
        }).pipe(catchError((err) => throwError(err)));
    }

    changePassword(pass: string): Observable<any> {
        return this.apiService
            .put(environment.serverUrl + 'api/users/my/password', { password: pass })
            .pipe(catchError((err) => of(false)));
    }

    updateAccount(account: Account, user: User): Observable<any> {
        return forkJoin({
            account: this.apiService.put(environment.serverUrl + 'api/accounts/my', account),
            user: this.apiService.put(environment.serverUrl + 'api/users/my', user),
        }).pipe(
            map(({ account, user }) => {
                const credentials = this.credentialsService.credentials;
                if (credentials) {
                    credentials.user = {
                        _id: user._id,
                        email: user.email,
                        username: user.username,
                        account: account,
                        role: Object.keys(user.roles)[0],
                    };
                    this.credentialsService.setCredentials(credentials, false);
                    this.message.sendMessage(commonMessageAction.UpdateUserDetail, credentials.user);
                }

                return credentials;
            }),
            catchError((err) => throwError(err)),
        );
    }
}
