import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome.component';
import { WelcomeRoutingModule } from './welcome-routing.module';
import { ScalioVideoSectionModule } from '../../components/atomic/organisms/o-scalio-video-section/o-scalio-video-section.module';
import { LinkModule } from '../../@shared/atomic/atoms/a-link/a-link.module';
import { ImageModule } from '../../@shared/atomic/atoms/a-image/a-image.module';

@NgModule({
    declarations: [WelcomeComponent],
    imports: [CommonModule, WelcomeRoutingModule, ScalioVideoSectionModule, LinkModule, ImageModule],
})
export class WelcomeModule {}
