<!--component html goes here -->
<div
    class="a-video"
    [ngClass]="[containerClass ? containerClass : '']">
    <video
        #target
        class="video-js"
        playsinline>
        <source #source />
    </video>
</div>
