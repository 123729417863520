<div [style.visibility]="showPage ? 'visible' : 'hidden'">
    <o-navbar
        [navItems]="navbarMenu"
        [hideInRoutes]="hideInRoutes"
        [showConnectRoutes]="showConnectRoutes"></o-navbar>
    <div class="body">
        <router-outlet></router-outlet>
    </div>
    <o-footer [hideInRoutes]="hideInRoutes.concat(['/puzzle'])">
        <ng-container header>
            <div class="d-flex flex-column flex-lg-row align-items-center justify-content-between">
                <div class="footer-logo">
                    <a-image imageConfig="/assets/img/logo.png"></a-image>
                </div>
                <p class="text-white text-center my-4">{{ date }} Virtue Animation. All right reserved</p>
                <div class="d-flex flex-column flex-lg-row align-items-center">
                    <a
                        class="d-flex text-decoration-none align-items-center"
                        href="https://twitter.com/BasemintGang"
                        target="_blank">
                        <a-icon source="/assets/icons/twitter-social-icon.svg"></a-icon>
                        <span class="ms-2 text-white small">Basemint Gang</span>
                    </a>
                    <a
                        class="d-flex text-decoration-none align-items-center mx-4 my-4"
                        href="https://mobile.twitter.com/virtueanimation"
                        target="_blank">
                        <a-icon source="/assets/icons/twitter-social-icon.svg"></a-icon>
                        <span class="ms-2 text-white small">Virtue Animation</span>
                    </a>
                    <a
                        class="d-flex text-decoration-none align-items-center"
                        href="https://medium.com/@Virtueanimation"
                        target="_blank">
                        <a-icon source="/assets/icons/medium-social-icon.svg"></a-icon>
                        <span class="ms-2 text-white small">Medium</span>
                    </a>
                </div>
            </div>
        </ng-container>
        <ng-container content>
            <div class="scalio-content">
                <p class="me-2 mb-0 text-white">Web3 technology powered by</p>
                <a
                    target="_blank"
                    href="https://scal.io">
                    <a-icon source="/assets/icons/scalio-logo.svg"></a-icon>
                </a>
            </div>
        </ng-container>
    </o-footer>
</div>
