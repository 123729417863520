export interface ICnnMetaMaskState {
    chainId: number;
    userAccountBalance: number;
    userAccountAddress: string;
}

export const initialState: ICnnMetaMaskState = {
    userAccountAddress: '',
    userAccountBalance: 0,
    chainId: 0,
};
