import { Component, OnInit } from '@angular/core';
import { PageBase } from '@src/app/@shared/base/page.base';
import { VideoOptions } from '../../components/atomic/organisms/o-scalio-video-section/o-scalio-video.model';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent extends PageBase implements OnInit {
    isPlaying = true;
    headerVideoBlock!: VideoOptions;
    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.headerVideoBlock = {
            fluid: true,
            aspectRatio: '16:9',
            autoplay: true,
            loop: true,
            controls: false,
            muted: true,
            preload: 'metadata',
            poster: 'assets/img/welcome-poster.webp',
            sources: [
                {
                    src: '/assets/videos/welcome_video.mp4',
                    type: 'video/mp4',
                },
            ],
        } as VideoOptions;

        this.loadingStateService?.stop();
    }
}
