<nav
    #nav
    *ngIf="showComponent"
    (scroll)="onWindowScroll()"
    class="navbar navbar-expand-lg fixed-top o-navbar"
    [ngClass]="[componentClass ? componentClass : '']">
    <div class="menu-container">
        <ng-container *ngTemplateOutlet="templateLogo"></ng-container>
        <div class="hide-desktop bars-mobile">
            <a-icon
                [iconSet]="true"
                source="lni lni-menu"
                (click)="openMenu()"></a-icon>
        </div>
    </div>
    <div
        class="collapse navbar-collapse justify-content-center"
        id="navbarSupportedContent">
        <ng-container *ngTemplateOutlet="templateNavItems"></ng-container>
        <ng-content></ng-content>
    </div>

    <div class="hide-mobile">
        <a
            *ngIf="!showConnectWallet"
            href="https://mint.basemintgang.com/"
            class="btn btn-primary">
            Mint Now
        </a>
        <div *ngIf="showConnectWallet">
            <a
                *ngIf="!user || !user.userAccountAddress"
                (click)="openModal()"
                [attr.data-target]="'#' + modalId"
                class="btn btn-primary px-5">
                Connect Wallet
            </a>
            <div *ngIf="user && user.userAccountAddress">
                <div
                    ngbDropdown
                    role="group"
                    aria-label="User menu items">
                    <span
                        [title]="user.userAccountAddress"
                        ngbDropdownToggle
                        class="btn btn-primary mb-4 px-5">
                        {{ truncateSting(user.userAccountAddress) }}
                    </span>
                    <ul
                        ngbDropdownMenu
                        placement="bottom-end"
                        role="button"
                        class="menu-wrapper">
                        <!-- <li ngbDropdownItem>My Profile</li> -->
                        <li
                            ngbDropdownItem
                            (click)="logout()">
                            Logout
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="showThemeToggle"
        class="hide-mobile">
        <a-theme-toggle></a-theme-toggle>
    </div>
</nav>

<ng-template
    #modalmenu
    let-modal>
    <div class="o-navbar mobile-menu hide-desktop">
        <div class="modal-dialog mobile-navbar">
            <div class="modal-content">
                <div class="modal-header">
                    <a
                        aria-label="logo"
                        class="navbar-logo"
                        data-dismiss="modal"
                        routerLink="/">
                        <a-image [imageConfig]="logoUrl"></a-image>
                    </a>
                    <svg-icon
                        data-dismiss="modal"
                        aria-label="Close"
                        class="icon-close"
                        [src]="iconClose"
                        (click)="modal.dismiss('close')"></svg-icon>
                </div>
                <div
                    data-dismiss="modal"
                    class="modal-body">
                    <ng-container *ngTemplateOutlet="templateNavItems"></ng-container>
                    <button
                        *ngIf="buttonMobile"
                        type="button"
                        class="hide-desktop m-0 btn btn-dark btn-sm"
                        (click)="click(true)">
                        {{ buttonMobile.label | translate }}
                    </button>
                    <!-- <a
                        *ngIf="buttonMobile"
                        type="button"
                        class="hide-desktop m-0 btn btn-dark btn-sm"
                        style="text-align: center; margin: 0 !important"
                        href="https://mint.basemintgang.com/">
                        {{ 'Mint Now' | translate }}
                    </a> -->
                    <div
                        *ngIf="!showConnectWallet"
                        class="d-flex justify-content-center my-5">
                        <a
                            href="https://mint.basemintgang.com/"
                            class="btn btn-primary mt-3">
                            Mint Now
                        </a>
                    </div>
                    <div *ngIf="showConnectWallet">
                        <a
                            *ngIf="!user || !user.userAccountAddress"
                            (click)="closeMenu(); openModal()"
                            [attr.data-target]="'#' + modalId"
                            class="btn btn-primary mt-3 px-5">
                            Connect Wallet
                        </a>
                        <div *ngIf="user && user.userAccountAddress">
                            <div
                                ngbDropdown
                                role="group"
                                aria-label="User menu items">
                                <span
                                    [title]="user.userAccountAddress"
                                    ngbDropdownToggle
                                    class="btn btn-primary mb-4 px-5">
                                    {{ truncateSting(user.userAccountAddress) }}
                                </span>
                                <ul
                                    ngbDropdownMenu
                                    placement="bottom-end"
                                    role="button"
                                    class="menu-wrapper">
                                    <!-- <li ngbDropdownItem>My Profile</li> -->
                                    <li
                                        ngbDropdownItem
                                        (click)="logout()">
                                        Logout
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #templateLogo>
    <a
        aria-label="logo"
        class="navbar-logo"
        data-dismiss="modal"
        routerLink="/home">
        <a-image [imageConfig]="logoUrl"></a-image>
    </a>
</ng-template>
<ng-template #templateNavItems>
    <m-navigation
        [navlinks]="items"
        (closeModal)="closeMenu()"></m-navigation>
</ng-template>

<ng-template
    #networkchangePopup
    let-modal>
    <div class="modal-body">
        <div>
            <a-network-change
                [btnDisabled]="!hasSupportedNetwork"
                (onClose)="closeMenu()"></a-network-change>
        </div>
    </div>
</ng-template>

<ng-template
    #accountChangePopup
    let-modal>
    <div class="modal-body account-change-modal">
        <div
            (click)="closeMenu()"
            class="close-button"
            role="button">
            <a-icon
                [white]="true"
                aria-label="Close"
                source="/assets/icons/close-icon.svg"></a-icon>
        </div>
        <div>
            <h5 class="text-white fw-bold text-uppercase text-start mb-3">
                Hi! looks like you switched wallet accounts
            </h5>
            <div class="pb-4">You would need to sign in as you have switched your wallet account.</div>
            <div class="d-grid gap-4">
                <button
                    (click)="openConnectModal()"
                    class="btn-next-clue btn btn-primary my-2">
                    <span>Sign In</span>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- <div class="waitlist-modal">
    <o-waitlist-modal
        [hasForm]="false"
        [(isOpen)]="modalHandler"
        [id]="modalId">
        <ng-container headerContent>
            <div class="modal-mint-content w-100 py-lg-7 px-lg-5">
                <h5 class="text-white fw-bold text-uppercase text-start mb-3">CONNECT WALLET</h5>
                <p class="small text-white text-start mb-5">
                    Choose one of available wallet providers or create a new wallet.
                </p>
                <p>
                    <a-button
                        buttonStyle="btn w-100 px-4 wallet-button"
                        (click)="loginMetamask('metamask')"
                        [disabled]="modalSaveloading"
                        [loading]="modalSaveloading">
                        <div class="wrapper">
                            <div><a-icon source="/assets/icons/metamask.svg"></a-icon></div>
                            <p class="m-0 text-center">Metamask</p>
                            <div></div>
                        </div>
                    </a-button>
                </p>
                <a-button
                    buttonStyle="btn w-100 px-4 wallet-button"
                    (click)="loginWalletConnect('walletconnect')"
                    [disabled]="modalSaveloading"
                    [loading]="modalSaveloading">
                    <div class="wrapper">
                        <div><a-icon source="/assets/icons/wallet.svg"></a-icon></div>
                        <p class="m-0 text-center">Wallet Connec&zwnj;t</p>
                        <div></div>
                    </div>
                </a-button>
                <!-- Check with Tamesh -->
<!-- <p
                    class="small text-white text-start mt-4"
                    *ngIf="walletError">
                    {{ walletError }}
                </p>
            </div>
        </ng-container>
    </o-waitlist-modal>
</div> -->
