<div class="welcome-page">
    <o-scalio-video-section
        [video]="headerVideoBlock"
        [overlay]="false"
        [hasVideo]="true"
        [isPlaying]="isPlaying"></o-scalio-video-section>
    <div class="enter px-5">
        <div class="logo-loader">
            <a-image imageConfig="/assets/img/logo.png"></a-image>
        </div>
        <div>
            <h1
                data-aos="fade-up"
                data-aos-delay="0"
                data-aos-once="true"
                class="lead contrast-color bold-weight text-uppercase">
                Step into our world
            </h1>
            <a-link
                url="/home"
                componentClass="btn btn-primary btn-enter animate__animated animate__fadeInUp animate__faster">
                <span>Enter the BaseMint</span>
            </a-link>
        </div>
    </div>
</div>
