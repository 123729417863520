<div
    class="m-navbar-item"
    [ngClass]="[containerClass ? containerClass : '']">
    <ul
        *ngIf="links"
        class="navbar-nav">
        <ng-container *ngFor="let item of links">
            <li
                *ngIf="!item.subMenuItems && !item.hasLanguages"
                class="nav-item">
                <button
                    [ngClass]="{
                        'nav-link no-subitems': true,
                        'hide-mobile': item.hideMobile,
                        'hide-desktop': item.hideDesktop
                    }"
                    [routerLink]="item.url"
                    routerLinkActive="active"
                    [title]="item.label | translate"
                    (click)="click(item)">
                    {{ item.label | translate }}
                    <span class="sr-only">(current)</span>
                </button>
            </li>
            <li
                *ngIf="item.subMenuItems"
                ngbDropdown
                [open]="item.isOpen"
                (mouseleave)="item.isOpen = false"
                (mouseover)="item.isOpen = true"
                role="group"
                aria-label="Button group with nested dropdown"
                [ngClass]="{
                    'nav-item subitems': true,
                    'nav-icon': item.icon
                }">
                <a
                    *ngIf="item.icon"
                    [ngClass]="{
                        'hide-mobile': item.hideMobile,
                        'hide-desktop': item.hideDesktop
                    }"
                    class="nav-link"
                    routerLinkActive="active"
                    ngbDropdownToggle>
                    <i [class]="item.icon"></i>
                </a>
                <button
                    ngbDropdown
                    class="nav-link"
                    routerLinkActive="active"
                    [routerLink]="item.url"
                    [ngClass]="{
                        'hide-mobile': item.hideMobile,
                        'hide-desktop': item.hideDesktop,
                        'd-flex justify-content-between cursor-pointer': true
                    }">
                    <div
                        class="d-flex align-items-center justify-content-between w-100"
                        id="dropdownBasic1"
                        role="button"
                        ngbDropdownToggle>
                        {{ item.label | translate }}
                    </div>
                </button>
                <div
                    [ngClass]="{
                        'dropdown-menu animatedDropdown fadeIn': true
                    }"
                    ngbDropdownMenu>
                    <ng-container *ngFor="let subItems of item.subMenuItems">
                        <button
                            routerLinkActive="active"
                            (click)="click(item, subItems)"
                            [routerLink]="subItems.url"
                            ngbDropdownItem>
                            {{ subItems.label | translate }}
                        </button>
                    </ng-container>
                </div>
            </li>
            <li
                *ngIf="item.hasLanguages"
                (click)="click(item)"
                [ngClass]="{
                    'hide-desktop': item.hideDesktop,
                    'hide-mobile': item.hideMobile,
                    'nav-item': true
                }">
                <m-language-selector [label]="item.langLabel"></m-language-selector>
            </li>
        </ng-container>
        <li class="nav-item text-center">
            <a
                class="nav-link no-subitems me-0"
                href="https://mint.basemintgang.com/">
                Mint Now
            </a>
        </li>
    </ul>
</div>
