import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconModule } from '@src/app/@shared/atomic/atoms/a-icon/a-icon.module';
import { NetworkChangeComponent } from './a-network-change.component';

@NgModule({
    declarations: [NetworkChangeComponent],
    imports: [CommonModule, TranslateModule, IconModule],
    exports: [NetworkChangeComponent],
})
export class NetworkChangeModule {}
