import { AngularSvgIconModule } from 'angular-svg-icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './o-navbar.component';
import { IconModule } from '../../atoms/a-icon/a-icon.module';
import { ThemeToggleModule } from '../../atoms/a-theme-toggle/a-theme-toggle.module';
import { NavbarItemModule } from '../../molecules/m-navbar-item/m-navbar-item.module';
import { WaitlistFormModule } from '@src/app/components/atomic/organisms/o-waitlist-form/o-waitlist-form.module';
import { ImageModule } from '../../atoms/a-image/a-image.module';
import { WaitlistModalModule } from '@src/app/components/atomic/organisms/o-waitlist-modal/o-waitlist-modal.module';
import { ButtonModule } from '../../atoms/a-button/a-button.module';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NetworkChangeModule } from '../../../../components/atomic/atoms/a-network-change/a-network-change.module';

@NgModule({
    declarations: [NavbarComponent],
    imports: [
        CommonModule,
        TranslateModule,
        AngularSvgIconModule,
        IconModule,
        ThemeToggleModule,
        NavbarItemModule,
        RouterModule,
        WaitlistFormModule,
        ImageModule,
        WaitlistModalModule,
        ButtonModule,
        NgbDropdownModule,
        NetworkChangeModule,
    ],
    exports: [NavbarComponent],
})
export class NavbarModule {}
