import { Routes, Route } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ShellComponent } from './shell.component';
import { Injectable } from '@angular/core';
import { environment } from '@src/environments/environment';

/**
 * Provides helper methods to create routes.
 */
@Injectable({
    providedIn: 'root',
})
export class Shell {
    constructor(private http: HttpClient) {}
    /**
     * Creates routes using the shell component and authentication.
     * @param routes The routes to add.
     * @return The new route using shell as the base.
     */
    static childRoutes(routes: Routes): Route {
        return {
            path: '',
            component: ShellComponent,
            children: routes,
        };
    }

    getNavbarMenu(): any[] {
        return environment.navbarMenu;
    }
}
