import { NgModule } from '@angular/core';
import { WelcomeModule } from './welcome/welcome.module';
import { WelcomeRoutingModule } from './welcome/welcome-routing.module';
import { GalleryComponent } from './gallery/gallery.component';

@NgModule({
    imports: [WelcomeRoutingModule, WelcomeModule],
    exports: [WelcomeModule],
    declarations: [],
})
export class PagesModule {}
